// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
import MainLogo from '../MainLogo'

import { Store } from 'Store'

import withTheme from 'hoc/withTheme'

import type { Theme } from './themes/__supportedThemes'


import styles from './styles.module.scss'

type Props = {
    theme: Theme
}

function Hero (props: Props) {
    const { theme, children } = props
    const { state } = React.useContext(Store)
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

    return (
        <div
            className={styles.mainContainer}
            style={{ '--primary-color': theme.primaryColor }}
        >
            <div className={styles.bgWrapper} style={{
                backgroundImage: isIE11 && `url(${theme.headerBg})`,
                '--hero-bg': `url(${theme.background})`,
                '--hero-bg-tablet': `url(${theme.backgroundTablet})`,
                '--hero-bg-mobile': `url(${theme.backgroundMobile})`,
            }}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <MainLogo title={state.campaign.client.name} />
                    </div>
                </div>
            </div>
             {children}
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
