
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns9307e0fabf6ddd4bbb7fb8c586a487c4en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '9307e0fabf6ddd4bbb7fb8c586a487c4', flattenObject(ns9307e0fabf6ddd4bbb7fb8c586a487c4en_US), true, true);
        
            import ns9307e0fabf6ddd4bbb7fb8c586a487c4en from './en.yaml'
            i18n.addResourceBundle('en', '9307e0fabf6ddd4bbb7fb8c586a487c4', flattenObject(ns9307e0fabf6ddd4bbb7fb8c586a487c4en), true, true);
        
        export default '9307e0fabf6ddd4bbb7fb8c586a487c4';
    