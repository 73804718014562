// @flow
import React from 'react'
import transDomain from './translations/index.translations'
import { withTranslation, Trans } from 'react-i18next'
import { Redirect, withRouter } from 'react-router-dom'

import supportedThemes from './themes/__supportedThemes'
import withTheme from 'hoc/withTheme'

import { Store } from 'Store.js'

import styles from './styles.module.scss'

function SuccessInfoArea ({ t, location, theme }) {
    const { state } = React.useContext(Store)
    const [redirect, setRedirect] = React.useState(false)
    const { email, newVehicleModel, newVehicleModelYear } = state.lead.fields

    const handleRedirect = React.useCallback(() => {
        setRedirect(true)
    }, [])

    if (redirect) {
        return <Redirect to={`${location.pathname.replace('success', 'step-1')}${location.search}`} push />
    }

    return (
        <div className={styles.container}>
            <span style={{
                color: theme.yearModelColor
            }} className={styles.yearModelTitle}>
                <Trans i18nKey='title' t={t} values={{
                    year: newVehicleModelYear,
                    model: newVehicleModel,
                }}>
                    A sales representative may be in touch with you shortly to discuss your interest in the <strong>{newVehicleModelYear} {newVehicleModel}.</strong>"
                </Trans>
            </span>
            <div className={styles.separator}>
                <div className={styles.innerBox}>
                    <span style={{
                        color: theme.confirmColor
                    }}>
                        {t('confirm')}
                        <br />
                        <div className={styles.email} style={{
                            color: theme.primaryColor,
                            backgroundColor: theme.bgEmailColor
                        }}>{email}</div>
                    </span>
                    <span className={styles.information} style={{
                        color: theme.informationColor
                    }}>
                        Not the correct email address? <strong onClick={handleRedirect} style={{ color: theme.secondaryColor }}>Click Here</strong> to change.
                    </span>
                </div>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(withTranslation(transDomain)(withRouter((SuccessInfoArea))))
