// @flow
import * as React from 'react'

import Hero from 'components/Hero'
import LeadGenForm from 'components/LeadGenForm/Step1'
import Sidebar from 'components/Sidebar'
import Steps from 'components/Steps'

import { Store } from 'Store'
import { updateLead } from 'Actions'

import { Redirect } from 'react-router-dom'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    const handleSelect = React.useCallback(async (make, model, year, color) => {
        const lead = {
            ...state.lead,
            fields: {
                ...state.lead.fields,
                newVehicleMake: make,
                newVehicleModel: model,
                newVehicleModelYear: year,
                newVehicleModelColor: color
            }
        }
        await updateLead(dispatch, state.code, lead)
        setSubmitted(true)
    }, [state.lead, state.code, dispatch])

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-1', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-1', 'step-2')}${props.location.search}`} push />
    }
    return (
        <Hero>
            <div className={styles.layoutWrapper}>
                <div className={styles.layout}>
                    <div className={styles.sidebar}>
                        <Sidebar background={0} />
                    </div>

                    <div className={styles.step}>
                        <Steps step={1} />

                        <div className={styles.container}>
                            <div className={styles.formContainer}>
                                <LeadGenForm
                                    onSelect={handleSelect}
                                    onSubmit={async (data, done) => {
                                        try {
                                            let lead = {
                                                fields: data,
                                                isComplete: false
                                            }
                                            await updateLead(dispatch, state.code, lead)
                                            setSubmitted(true)
                                        } catch (err) {
                                            alert('Oops something went wrong')
                                            console.error(err)
                                            done(err)
                                        }
                                    }}
                                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Hero>
    )
}
