// @flow
import * as React from 'react'

import Hero from 'components/Hero'
import SuccessInfoArea from 'components/SuccessInfoArea'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import HeaderWithVoucher from 'components/HeaderWithVoucher'
import styles from './styles.module.scss'

function SuccessInfo () {
    return (
        <>
            <Hero />
            <div className={styles.layoutWrapper}>
                <div className={styles.container}>
                    <div className={styles.formContainer}>
                        <HeaderWithVoucher />
                        <SuccessInfoArea />
                    </div>
                </div>
            </div>
        </>

    )
}

export default withTheme(supportedThemes)(SuccessInfo)
