
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns6ffab8b3031a6df8b4ba674b852e6134en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '6ffab8b3031a6df8b4ba674b852e6134', flattenObject(ns6ffab8b3031a6df8b4ba674b852e6134en_US), true, true);
        
            import ns6ffab8b3031a6df8b4ba674b852e6134en from './en.yaml'
            i18n.addResourceBundle('en', '6ffab8b3031a6df8b4ba674b852e6134', flattenObject(ns6ffab8b3031a6df8b4ba674b852e6134en), true, true);
        
        export default '6ffab8b3031a6df8b4ba674b852e6134';
    