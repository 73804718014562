// @flow
import * as React from 'react'
import RegistrationForm from 'components/RegistrationForm'

// import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function RegisterForm (props) {
    return (
        <div className={styles.registerForm} style={{
            '--placeholder-color': props.theme.placeholderColor
        }}>
            <div className={styles.formContainer}>
                <div
                    className="register-text-masked-layers js-register-text-masked-layers"
                >
                    <h3 className="register-text-layer js-masked-layer">
                        <Trans data-mask="right" i18nKey="title">
                            <strong className={styles.title} style={{ color: props.theme.titleColor }}>
                                Kick-start the year with a great offer.
                            </strong>
                        </Trans>
                    </h3>
                </div>

                <RegistrationForm />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterForm))
