// @flow

import * as React from 'react'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes'

type Props = {
    y: string,
    x: string,
    width: string,
    primaryColor: string,
    secondaryColor: string,
    eventColor: string,
    logoRefCallback?: (elm: React.Ref) => void,
}

const EventLogo = React.forwardRef(function (props: Props, ref: React.Ref) {
    return (
        <svg
            x={props.x}
            y={props.y}
            width={props.width}
            height={props.height}
            viewBox={props.viewBox || `0 0 ${props.width} ${props.height}`}
            ref={ref}
        >
        <defs>
            <path
            id="path-1"
            d="M0.14773444 -0.000298288509L23.5434042 -0.000298288509 23.5434042 24.3595819 0.14773444 24.3595819z"
            ></path>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-122 -95)">
            <g transform="translate(122 95)">
                <path
                fill={props.newYearColor}
                d="M114.393293 108.902152L111.519786 122.000298 121.411273 121.431164 122.134185 118.132093 116.191799 118.473932 116.562919 116.804411 122.368514 116.470626 123.09202 113.171555 117.286128 113.50534 117.618887 111.974225 123.560976 111.63179 124.284185 108.333017z"
                ></path>
                <path
                fill={props.newYearColor}
                d="M128.246608 108.104767L132.527576 107.85838 133.407203 116.51352 138.157424 107.534439 142.829436 107.265681 135.147127 120.640341 130.181905 120.926102z"
                ></path>
                <path
                fill={props.newYearColor}
                d="M146.548342 107.05145L156.439828 106.482316 155.716322 109.781089 149.773936 110.123225 149.441474 111.655235 155.247069 111.320854 154.523563 114.619925 148.718266 114.954008 148.346848 116.623529 154.289234 116.281392 153.566322 119.580463 143.674835 120.149597z"
                ></path>
                <path
                fill={props.newYearColor}
                d="M163.892656 111.695594L162.289524 119.078234 158.341017 119.30553 161.214524 106.20798 165.280492 105.973227 168.290789 112.664136 169.815416 105.712224 173.764219 105.485227 170.890713 118.583374 167.098327 118.801422z"
                ></path>
                <path
                fill={props.newYearColor}
                d="M180.923571 108.485771L177.424393 108.687115 178.187153 105.230548 189.114089 104.601756 188.351627 108.058025 184.872374 108.258475 182.76133 117.900353 178.812526 118.12735z"
                ></path>
                <path
                fill={props.newCarColor}
                d="M25.6557151 10.5214709L36.6772158 9.88701125 40.1175131 33.5657496 43.5369945 9.49237555 52.2464274 8.99095257 45.3191452 57.761422 36.3783568 58.2759697 31.5103762 29.579422 27.3605491 58.7949917 18.7284329 59.2919403z"
                ></path>
                <path
                fill={props.newCarColor}
                d="M78.5726867 7.47594523L87.3594364 6.97004792 87.1691183 26.6078719 86.865204 35.743554 89.5582054 26.4703609 95.6061584 6.4954709 104.238869 5.99882054 104.367631 25.5408939 104.361089 34.736532 106.834035 25.3989086 112.485591 5.52424352 121.118005 5.02729487 106.395114 54.3233487 97.1459509 54.8557936 96.7043534 26.2138328 87.8970851 55.3882386 78.5711999 55.9248597z"
                ></path>
                <path
                fill={props.newCarColor}
                d="M127.456728 28.0375985L128.789252 18.6555301 121.96159 4.93217066 129.996583 4.46982347 133.254591 12.1829677 138.704232 3.96869878 146.739522 3.5060533 135.939564 18.2438919 134.607039 27.6262587z"
                ></path>
                <path
                fill={props.newCarColor}
                d="M144.419425 27.0613301L147.773485 3.44672372 165.685096 2.41554034 164.799522 8.64678729 154.039114 9.26603423 153.706652 11.6031247 164.219944 10.9978973 163.334667 17.2291443 152.821673 17.8340733 152.455013 20.4187433 163.215421 19.7994963 162.330442 26.030445z"
                ></path>
                <path
                fill={props.newCarColor}
                d="M179.181 8.592l-3.417 7.746 4.673-.269-1.256-7.477zm2.74 16.311l-.458-2.713-8.495.49-1.242 2.81-8.106.466L175.682 1.84l8.99-.517 5.354 23.114-8.106.466z"
                ></path>
                <g>
                <g transform="translate(191.21)">
                    <mask id="mask-2" fill="#fff">
                    <use xlinkHref="#path-1"></use>
                    </mask>
                    <path
                    fill={props.newCarColor}
                    d="M16.196 8.02c.18-1.274-.872-1.775-2.075-1.706l-4.354.25-.528 3.718 4.354-.25c1.204-.07 2.417-.701 2.603-2.011zm-3.057 15.592l-2.201-7.247-2.584.148-1.056 7.435-7.15.412L3.502.745 16.103.02c5.522-.318 7.973 3.193 7.344 7.618-.578 4.072-3.256 6.368-5.263 7.396l3.096 8.11-8.141.468z"
                    mask="url(#mask-2)"
                    ></path>
                </g>
                <path
                    fill={props.newCarColor}
                    d="M61.3452988 34.2754377L72.5981549 33.6278533 73.6826708 25.9928606 62.4298147 26.640445 63.8905062 16.3566504 76.192491 15.6485134 77.3433209 7.54640098 55.946509 8.77773594 49.0192268 57.5482054 72.5710941 56.1927824 73.7109212 48.1682249 59.2538811 49.0001516z"
                ></path>
                <path
                    fill={props.newYearColor}
                    d="M154.560853 76.358577L159.842181 76.0450758 160.665604 65.0987824 161.25797 57.5950367 158.567645 65.2231687z"
                ></path>
                <path
                    fill={props.newYearColor}
                    d="M167.119 91.236l-8.465.502.349-8.708-6.657.395-3.386 8.888-8.32.494 18.753-48.97 9.477-.563-1.751 47.962zm-42.05 2.511c-8.055.482-11.403-3.391-8.675-13.854l5.395-20.691c2.565-9.838 7.861-14.536 16.715-15.066 9.942-.595 11.208 3.864 8.98 14.605l-8.913 1.301c1.423-6.003 1.88-8.029-2.33-7.777-2.902.174-4.636 1.892-5.796 6.342l-5.518 21.16c-1.16 4.45-.923 6.28 2.778 6.06 3.92-.235 4.462-1.497 6.646-8.776l8.34.346c-3.198 9.8-6.447 15.682-17.622 16.35zm-27.24 1.387l-7.504.432-1.143-13.208-4.945 13.558-7.505.432-3.406-23.225 8-.46.992 12.573 5.627-12.955 5.38-.31 1.524 12.146 5.06-12.524 8.035-.463L97.83 95.134zM69.935 79.498l-11.95.688-.332 2.336 11.703-.673-.886 6.231-11.702.674-.367 2.584 11.95-.688-.885 6.232-19.1 1.099 3.354-23.614 19.1-1.1-.885 6.231zM44.37 98.211l-6.867.395-7.008-11.465-1.7 11.966-7.15.412L25 75.904l7.362-.424 6.623 10.715 1.59-11.187 7.15-.412-3.355 23.615zm139.744-26.56l-2.305.138-2.682 19.063-8.501.507 6.797-48.315 11.238-.67c8.501-.506 12.963 1.295 11.367 12.64l-.402 2.856c-.967 6.869-3.307 10.3-6.91 12.045l9.55 28.415h.011l9.603-67.324-45.876 2.6-44.582 2.525-8.427 25.661-95.87 5.552-5.433 38.247L0 109.98l192.683-11.097-8.57-27.23z"
                ></path>
                <path
                    fill={props.newYearColor}
                    d="M187.741 49.93l-2.881.171-2.042 14.51 2.954-.176c4.179-.249 4.795-1.587 5.403-5.909l.446-3.164c.553-3.936.371-5.686-3.88-5.432"
                ></path>
                </g>
            </g>
            </g>
        </g>
        </svg>
    )
})

EventLogo.defaultProps = {
    x: 0,
    y: 0,
    width: '215px',
    height: '122px'
}

export default withTheme(themes)(EventLogo)
