
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsdd7797ae9d7ee2cc96d3e958790c2f93en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'dd7797ae9d7ee2cc96d3e958790c2f93', flattenObject(nsdd7797ae9d7ee2cc96d3e958790c2f93en_US), true, true);
        
            import nsdd7797ae9d7ee2cc96d3e958790c2f93en from './en.yaml'
            i18n.addResourceBundle('en', 'dd7797ae9d7ee2cc96d3e958790c2f93', flattenObject(nsdd7797ae9d7ee2cc96d3e958790c2f93en), true, true);
        
        export default 'dd7797ae9d7ee2cc96d3e958790c2f93';
    