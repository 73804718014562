import React from "react"

function CheckMark ({
    color
}) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-72 -253)">
          <g transform="translate(31 244)">
            <g transform="translate(41 9)">
              <circle cx="9" cy="9" r="9" fill={color}></circle>
              <path
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 10.2431641L8.66259766 12.9057617 12.9916992 6"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CheckMark
